/*eslint-disable*/
import { extractDomain } from '@/services/utils/URL'

function transferDataUsingPostMessageAPI(destinationOrigin, data) {
  const correctExtractedDomain = extractDomain(destinationOrigin)
  const crossOriginWindow = window.open(destinationOrigin)

  if (!crossOriginWindow) {
    console.error('Failed to open destination window.')
    return
  }

  const sendData = () => {
    crossOriginWindow.postMessage(data, destinationOrigin)
  }
  const handleMessage = event => {
    // Ensure the message is coming from the intended destination
    if (event.origin === correctExtractedDomain && event.data === 'READY') {
      sendData()
      window.removeEventListener('message', handleMessage) // Clean up listener
    }
  }
  // Listen for acknowledgment from the destination window
  window.addEventListener('message', handleMessage)
}
export { transferDataUsingPostMessageAPI }
