/*eslint-disable*/
import { transferDataUsingPostMessageAPI } from '@/services/utils/PostMessageTransferData.js'
function isLinkCampMeetingLibrary(externalLink) {
    const { hostname, pathname } = new URL(externalLink)
    return (
        (hostname === 'apps.gideons.org' || hostname === 'apps.staging.gideons.org') &&
        pathname.toLowerCase().includes('campmeetinglibraryui')
    )
}
function transferAuthTokenSecurely(externalUrl) {
    let authToken
    try {
        authToken = JSON.parse(localStorage.getItem('vuex')).user.userLogin.access_token
    } catch (err) {
        console.error('ERROR: ', err)
    }
    const data = {
        type: 'CONN_AUTH_JWT_KEY',
        authToken: authToken
    }
    transferDataUsingPostMessageAPI(externalUrl, data)
}
export { transferAuthTokenSecurely, isLinkCampMeetingLibrary }
